import { Subscription } from 'rxjs';
import { ApiService } from './../services/api.service';
import { Router } from '@angular/router';
import { StateService } from './../services/state.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface msigRow {
  monitorNumber: string;
  date: string;
}

interface Alert {
  message: string;
  status: string;
}


@Component({
  selector: 'table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss']
})
export class TableListComponent implements OnInit {

  msigList;
  msig;
  monitorDetails;
  request;
  fullsizeLoader = false;
  alertWarning = false;
  alert: Alert;
  monitorDetailsSubscription: Subscription;
  serachSubscription: Subscription;
  apiURL;
  hideNext = true;
  hidePrev = false;
  dataSubscription: Subscription;
  allPagesSubscription: Subscription;
  nextPrevIdsObj = {
    prev: false,
    current: false,
    next: false
  }

  countPages;



  constructor(private modalService: NgbModal,
              private api: ApiService, 
              private state: StateService,
              private router: Router ) {}

  ngOnInit(): void {
    
    this.state.url$.subscribe({
      next: url => {
        this.apiURL = url;
      }
    })

    this.state.searchRequest$.subscribe({
      next: res => {
        this.request = res; 
       
      }
    })

    this.showData()
   

  }

  monitorCount() {
        this.allPagesSubscription = this.api.searchMonitorCount(this.request).subscribe({
            next: res => {
                this.countPages = res;
                this.hideNext = this.countPages > this.msigList.page;
                this.hidePrev = this.msigList.page > 1;
         

            },
            error: err => {

                this.state.shareAlertBody([{
                    status: 'danger',
                    message: err.error.title,
                    alwaysVisible: false
                }]);
            }
        });
    }

  ngOnDestroy(): void {
   
    if(this.monitorDetailsSubscription) {
      this.monitorDetailsSubscription.unsubscribe();
    }

    if(this.allPagesSubscription) {
      this.allPagesSubscription.unsubscribe();
    }
    
    if(this.serachSubscription) {
      this.serachSubscription.unsubscribe();
    }

    if(this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }

  }

  prevNextIdsModal(id) {

    this.msigList.list.forEach((el, i) => {
      if(el.id == id) {
       
        this.nextPrevIdsObj.current = id;
       
       // this.nextPrevIdsObj = '';
          if(i == 0 && this.msigList.list.length == 1 ) {
            this.nextPrevIdsObj.prev = false;
            this.nextPrevIdsObj.next = false;
          }
          else if(i == 0) {
            this.nextPrevIdsObj.next = this.msigList.list[i+1].id;
            this.nextPrevIdsObj.prev = false;
          } else if(i > 0 && (i < this.msigList.list.length - 1)) {
              this.nextPrevIdsObj.next = this.msigList.list[i+1].id;
              this.nextPrevIdsObj.prev = this.msigList.list[i-1].id;
          } else if(i == this.msigList.list.length - 1) {
            this.nextPrevIdsObj.next = false;
            this.nextPrevIdsObj.prev = this.msigList.list[i-1].id;
          }

        }
     
    });
    

  }

  showModalDetails(content, id){
    this.fullsizeLoader = true;
    //this.monitorDetails = null;

    if(id == 0 || id == null) {

      
      this.state.shareAlertBody([{
        status: 'warning',
        message: 'The value "null" is not valid.' ,
        alwaysVisible: false
      }]);

      this.fullsizeLoader = false;
   
    } else {
      this.monitorDetailsSubscription = this.api.getMonitorDetails(id).subscribe({
        next: res => {
          this.fullsizeLoader = false;
          this.monitorDetails = res;

          this.prevNextIdsModal(id)

          this.modalService.open(content, { centered: true, size: 'xl' });
        },
        error: err => {
          this.fullsizeLoader = false;
        },
        complete: () => {
          this.fullsizeLoader = false;
        }
      })
    }
  }

  downloadMonitor(id) {


    this.api.getFileMonitor(id).subscribe({
      next: res => {

       let filename = res.headers.get('content-disposition').split("filename=")[1].split(".")[0]; 

       let dataType = res.body.type;
       let binaryData = [];
       binaryData.push(res.body);

       let downloadLink = document.createElement('a');
       downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
       if (filename)
           downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
    },
      error: err => {
        console.log(err)
      }
    })

  }

  async download(id: number) {
    try {
       const res = await this.api.getFileMonitor(id);
       this.downloadFile(res);
    } catch (e) {
      
    }
 }

 downloadFile(data) {
    const url = window.URL.createObjectURL(data);
    window.open(url);
 }

  showData() {

    this.dataSubscription = this.state.data$.subscribe({
      next: res => {
        
        if(!res) {
          this.router.navigateByUrl('/');
        } else {
          
          this.msigList = res;
          this.monitorCount();

        }
      }
    })
  }

  changePage(action) {
   
    if(action == "next") {
      this.request.page = this.request.page + 1;
    }
    if(action == "prev" && this.request.page > 1) {
      this.request.page = this.request.page - 1;
    }

    this.fullsizeLoader = true;
    this.serachSubscription = this.api.searchMonitor(this.request).subscribe({
      next: res => {

        this.fullsizeLoader = false;
        let data = res;
        if(data.list.length>0) {
          this.state.shareData(res);
        } 

      },
      error: err => {
        this.fullsizeLoader = false;
      
        this.state.shareAlertBody([{
          status: 'danger',
          message: err.error.title ,
          alwaysVisible: false
        }]);
      }
    })

    this.monitorCount();

  }

}
