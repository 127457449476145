<div class="form-group">
  <div class="input-group">
    <input
      class="form-control"
      
      name="dp"
      [minDate]="minDateRange"
      [startDate]="setStartDate"
      [maxDate]="maxDateRange"
      [(ngModel)]="modelDate"
      [value]='dateValue'
      ngbDatepicker
      #d="ngbDatepicker"
      (dateSelect)="changePropertyValue()"
      aria-label="Wybierz datę z kalendarza" 
      alt="Wybierz datę"
      readonly
    />

    <div class="input-group-append">
      <button
        style="padding-left: 16px;"
        class="btn btn-outline-secondary calendar"
        (click)="d.toggle()"
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="currentColor"
          class="bi bi-calendar3"
          viewBox="0 0 16 16"
        >
          <path
            d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"
          />
          <path
            d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
          />
        </svg>
      </button>
    </div>
  </div>
</div>
