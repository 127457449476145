<fullsize-loader *ngIf="fullsizeLoader"></fullsize-loader>

<main-header>
  <div class="subTitle px-3 mb-3">
    <div class="fluid-container d-flex flex-wrap justify-content-start">
      <a class="arrow-back" role="link" aria-label="Powrót do ekranu głównego" [routerLink]="['']">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
        </svg>
        Wstecz
      </a>
      <div *ngIf="!archives" class="title">
        Pobierz monitor
      </div>
      <div *ngIf="archives" class="title">
        Pobierz monitor - Archiwum
      </div>
    </div>
  </div>

</main-header>

<div class="container table-container" style="margin-top:75px" >

<form [formGroup]="tableForm" *ngIf="tableForm">
  <div class="mb-3">

    <label for="formyear" class="form-label"
      >Wybierz rok</label
    >
    <select
      class="form-control"
      id="formyear"
      aria-label="Wybierz rok monitora"
      formControlName="year"
      [compareWith]="compareByID"
    >
    <option *ngFor="let year of years"
        [ngValue]="year">
        {{year}}
    </option>
    </select>
  </div>
 </form> 
<div class="row">
  <div class="col-sm-6 col-md-3 col-lg-2 monitor-tile" *ngFor="let msigRow of msigList; index as i">
    <div class="monitor-number"> {{ msigRow.monitorNumber}}</div>
    <div class="">{{ msigRow.date | date:'yyyy-LL-dd' }}</div>
    <div class=""> {{ msigRow?.dataLenght | formatBytes }}</div>
    <div class=""><a type="button" class="btn btn-link table-action_link" download href="{{apiURL}}/Monitor/Download?id={{msigRow.id}}&fileId=true" >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
      </svg>
      Pobierz</a></div>
  </div>
</div>
</div>
