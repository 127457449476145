import { AbstractControl, ValidatorFn } from '@angular/forms';


export class CheckNipValidator {

    static checkNip(control: AbstractControl) {

        if(control.value){

            let nip = control.value?.toString();
            var nipWithoutDashes = nip.replace(/-/g,"");
            var reg = /^[0-9]{10}$/;
            if(reg.test(nipWithoutDashes) == false) {
                return null;}
            else
            {
                var digits = (""+nipWithoutDashes).split("");
                var checksum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;

                console.log((parseInt(digits[9])==checksum))
                
                return {
                    checkNip:  (parseInt(digits[9])==checksum)
                }
                     
                 };
           
            }


    }

   

        
                // return {
                //     checkNipValidator: {
                //         valid: sum % 11 === controlNumber
                //     }
                // };
            // let val = control.value.toString();;

            // // if(control.value) {
            // //     let val = control.value.toString();
            // // }

            // //let val = control.value;
            // console.log(val)
            // let nipWithoutDashes = val.replace(/-/g,"");
            // let reg = /^[0-9]{10}$/;

            // if(reg.test(nipWithoutDashes) == false) {
            //     return null;
            // } else
            // {
            //     var digits = (""+nipWithoutDashes).split("");
            //     var checksum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;
                
            //     return {
            //         checkNipValidator: {
            //             valid: (parseInt(digits[9])==checksum)
            //         }
            //     };
            // }


}