import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpEvent,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';

import { throwError } from 'rxjs';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';



@Injectable()
export class MyInterceptor implements HttpInterceptor {



    public static ShowError: Subject<any> = new Subject<any>();

    intercept(req: HttpRequest<any>, next: HttpHandler, ): Observable<HttpEvent<any>> {


        return next.handle(req).pipe(
            map(event => {
                return event;
            }),
            catchError(error => {

                MyInterceptor.ShowError.next(error);

                // console.error('stop', error);
 
                return throwError(error);
            }),
            finalize(() => {
            
            })
        );


    }
}