import { AppComponent } from './../app.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService} from './../services/api.service';
import { ActivatedRoute, Router, UrlHandlingStrategy } from '@angular/router';
import { StateService } from './../services/state.service';
import { ChangeDetectorRef, Component, OnInit, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getLocaleDateFormat, Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { getUrlScheme } from '@angular/compiler';

interface msigRow {
  monitorNumber: string;
  date: string;
}

interface Alert {
  message: string;
  status: string;
}


@Component({
  selector: 'table-list',
  templateUrl: './list-by-year.component.html',
  styleUrls: ['./list-by-year.component.scss']
})
export class ListByYearComponent implements OnInit {

  msigList;
  msig;
  monitorDetails;
  request;
  fullsizeLoader = false;
  alertWarning = false;
  alert: Alert;
  monitorDetailsSubscription: Subscription;
  serachSubscription: Subscription;
  apiURL;
  hideNext = true;
  dataSubscription: Subscription;
  urlSubscription: Subscription;
  tableForm: FormGroup;
  currentYear;
  years = [];
  tryTofive = 0;
  waitForUrl;
  archives = false;

  constructor(private fb: FormBuilder,
              private api: ApiService, 
              private state: StateService,
              private location: Location,
              private route: ActivatedRoute,
              @Inject(DOCUMENT) private document: any,
              private http: HttpClient) {}

  ngOnInit(): void {

    this.generateYears();

      this.state.url$.subscribe({
        next: url => {
          this.apiURL = url;
  
          if(this.apiURL) {
            this.getData()
          }
          
        }
      })
    
  
  }

  ngOnDestroy(): void {  

    if(this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }

    // if(this.urlSubscription) {
    //   this.urlSubscription.unsubscribe();
    // }

  }

  

  urlState() {

    return new Promise((resolve, reject) => {
      this.state.url$.subscribe({
        next: url => {
          this.apiURL = url;
          // console.log(this.apiURL)
          if(this.apiURL == undefined) {
            reject('error');
          } else {
            resolve('done');
          }
        }
      })
    })

  } 

  fromApp() {
    return new Promise((resolve, reject) => {

      this.state.url$.subscribe({
        next: url => {
          this.apiURL = url;
          // console.log(this.apiURL)
          if(this.apiURL == undefined) {
            reject('error');
          } else {
            resolve('done');
          }
        }
      })



    })
  }

  getData() {
    this.route.params.subscribe(res => {
    
      if(res.year) {
        
        this.currentYear = res.year
        this.tableForm = this.tableFormInit();
        this.monitorByYear(this.currentYear)
        this.yearChanged()
      }
    });
  }

  tableFormInit() {
    return this.fb.group({
      year: [this.currentYear],
    })
  }

  yearChanged() {

    this.tableForm.get('year').valueChanges.subscribe({
      next: value => {

        this.currentYear = value;
        this.monitorByYear(value)
         this.location.go(`/pobieranie-monitora/${this.currentYear}`)
      }
    })
    
  }

  getApiUrl() {

    let url = `${document.location.origin}/`;//document.location.href;

    this.http.get(
      `${url}home/getapiurl`,
      { responseType: 'text' }
    ).subscribe({
      next: res => {
        this.apiURL = res;
        this.state.shareUrl(this.apiURL)
      },
      error: err => {
        console.log(err)
      }
    })

  }

  generateYears() {

    let yearToday = new Date().getFullYear();
    let startYear = 1996;


    while (startYear < yearToday) {
     
      this.years.length === 0 ? startYear : startYear = startYear + 1;

      let year = startYear;
      this.years.push(year.toString());

    }

  }

  monitorByYear(year) {

    this.fullsizeLoader = true;

    if (year < 2001) {
      this.archives = true;
    } else {

      this.archives = false;
    }

      this.dataSubscription = this.api.getMonitorByYear(year).subscribe({
        next: res => {
          this.msigList = res;
      
          this.fullsizeLoader = false;
          if(this.msigList.length == 0) {
            this.state.shareAlertBody([{
              status: 'info',
              message: 'Brak wyników. Zmień rok.' ,
              alwaysVisible: false
            }]);
          }
  
        },
        error: err => {
         console.log(err)
            this.state.shareAlertBody([{
              status: 'danger',
              message: 'Wystąpił bład.',
              alwaysVisible: false
            }]);
            this.tryTofive = 0;
            this.fullsizeLoader = false;

        }
      })


    }

 downloadFile(data) {
    const url = window.URL.createObjectURL(data);
    window.open(url);
 }

 compareByID(itemOne, itemTwo) {
  return itemOne == itemTwo;
}


}
