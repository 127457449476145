import { StateService } from './../services/state.service';
import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {

  contrastStatus = false;

  accessibilityObj = {
    contrast: false,
    fontSizeNormal: true,
    fontSizeBigger: false,
    fontSizeBigest: false,
  }

  constructor(private state: StateService, @Inject(DOCUMENT) private document: any) { }

  ngOnInit(): void {

    this.state.accessibility$.subscribe({
      next: res => {
        this.contrastStatus = res?.contrast;
      }
    })
 
  }

  contrast() {

    this.contrastStatus = this.contrastStatus ? false : true;
    this.accessibilityObj.contrast = this.contrastStatus;
    
    this.state.shareAccessibility(this.accessibilityObj);
    this.document.body.classList.toggle('msig-contrast', this.contrastStatus)

  }


  changefontSize(size) {

    let htmlTag = this.document.querySelector('HTML');

    switch (size) {
      case "normal":
        this.accessibilityObj.fontSizeNormal = true;
        this.accessibilityObj.fontSizeBigest = false;
        this.accessibilityObj.fontSizeBigger = false;
        htmlTag.style.fontSize = '16px';
      break;

      case "bigger":
        this.accessibilityObj.fontSizeNormal = false;
        this.accessibilityObj.fontSizeBigest = false;
        this.accessibilityObj.fontSizeBigger = true;
        htmlTag.style.fontSize = '18px';
      break;

      case "bigest":
        this.accessibilityObj.fontSizeNormal = false;
        this.accessibilityObj.fontSizeBigest = true;
        this.accessibilityObj.fontSizeBigger = false;
        htmlTag.style.fontSize = '20px';
      break;

      default:
        this.accessibilityObj.fontSizeNormal = true;
        this.accessibilityObj.fontSizeBigest = false;
        this.accessibilityObj.fontSizeBigger = false;
        htmlTag.style.fontSize = '16px';
        break;
    }

    this.state.shareAccessibility(this.accessibilityObj);

  }
}
