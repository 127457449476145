import { DeclarationComponent } from './declaration/declaration.component';
import { ListByYearComponent } from './list-by-year/list-by-year.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { TableListComponent } from './table-list/table-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { 
    path: '', 
    component: SearchFormComponent, 
    pathMatch: 'full'  
  },
  { 
    path: 'list', 
    component: TableListComponent, 
    pathMatch: 'full'  
  },
  { 
    path: 'pobieranie-monitora/:year', 
    component: ListByYearComponent, 
    pathMatch: 'full'  
  },
  { 
    path: 'deklaracja-dostepnosci', 
    component: DeclarationComponent,
    pathMatch: 'full'   
  },
  { 
    path: 'assets/MSiG_2_0_-_Instrukcja_użytkownika_systemu_v1.0.pdf', 
    redirectTo: 'assets/MSiG_2_0_-_Instrukcja_użytkownika_systemu_v1.0.pdf'
  },
  { 
    path: '**',
    redirectTo:'' ,
    component: SearchFormComponent 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
