import { StateService } from './../services/state.service';
import { List } from './../models/list.model';
import { ApiService } from './../services/api.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckNipValidator } from './CheckNipValidator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  closeResult: string;
  popover = true;
  searchForm: FormGroup;
  formOptionShow = false;
  alertWarning = false;
  fullsizeLoader = false;
  popoverTexts = {
    dateFrom: {
      text: "Zakres przeszukiwania.",
      title: '',
      example1: "Data publikacji od: 2017-01-01",
      example2: "Data publikacji do: 2023-01-01"
    },
    dateTo: {
      text: "Zakres przeszukiwania.",
      title: '',
      example1: "Data publikacji od: 2013-01-01",
      example2: "Data publikacji do: 2023-01-01"
    }
  }
  startDateFrom;
  startDateTo;
  todayDateControl;
  minFrom;
  blockSearchButton = false;
  baseList = [
    {
      name: 'Baza ogłoszeń',
      val: 'A'
    },
    {
      name: 'Baza KRS',
      val: 'B'
    }
  ];
  hidesignatureKRS = false;
  serachSubscription: Subscription;
  instanceOfPopover;
  year = new Date().getFullYear().toString();
  mindateControl = { year: 2001, month: 1, day: 1 };


  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private api: ApiService, private state: StateService) { }

  searchList(popoverNip = '') {

    this.fullsizeLoader = true;

    let nipForm = this.searchForm.get('nip');
    let textInPosition = this.searchForm.get('textInPosition');
    let textInBody = this.searchForm.get('textInBody');
    let krsForm = this.searchForm.get('krs')
    let entityName = this.searchForm.get('entityName')

    if (entityName.value) {
      this.searchForm.value.entityName = entityName.value.replace(/[?&]/g, "%26")
    }

    if (krsForm.value) {
      this.fillZeros()
    }

    if (nipForm.value) {

      nipForm.setValue(nipForm.value.toString())
    }

    if (textInPosition.value) {
      this.searchForm.get('textInPosition').setValue(textInPosition.value.replace(/\n|\r/g, " "))

    }

    if (textInBody.value) {
      this.searchForm.get('textInBody').setValue(textInBody.value.replace(/\n|\r/g, " "))
    }


    let objToSent = {
      textInBody: textInBody.value?.replace(/[?&]/g, "%26"),
      entityName: entityName.value?.replace(/[?&]/g, "%26"),
      textInPosition: textInPosition.value?.replace(/[?&]/g, "%26")
    }


    if (objToSent.entityName) {
      this.searchForm.value.entityName = objToSent.entityName;
    }

    if (objToSent.textInBody) {
      this.searchForm.value.textInBody = objToSent.textInBody;
    }

    if (objToSent.textInPosition) {
      this.searchForm.value.textInPosition = objToSent.textInPosition;
    }

    if (this.blockSearchButton === false) {


      this.serachSubscription = this.api.searchMonitor(this.searchForm.value).subscribe({
        next: res => {

          this.fullsizeLoader = false;
          let data: List = res;
          if (data.list.length > 0) {
            this.state.shareData(res);
            this.state.shareSearchRequest(this.searchForm.value);
            this.router.navigateByUrl('/list');
          } else {
            this.state.shareAlertBody([{
              status: 'info',
              message: 'Brak wyników. Zmień kryteria wyszukiwania.',
              alwaysVisible: false
            }]);
          }

        },
        error: err => {
          this.fullsizeLoader = false;

          this.state.shareAlertBody([{
            status: 'danger',
            message: 'Wystąpił bład.',
            alwaysVisible: false
          }]);
        }
      })
    } else {
      this.fullsizeLoader = false;
      // this.state.shareAlertBody([{
      //   status: 'danger',
      //   message: 'Wprowadzone nieprawidłowy numer NIP',
      //   alwaysVisible: false
      // }]);
      this.showNipWarning(popoverNip)
    }

  }


  ngOnInit(): void {

    this.selectTodayControl();
    this.searchForm = this.searchFormInit();
    this.nipValidation()
    var today = new Date();
    this.setDateRange('to', today, false, false, false, true)
    this.setDateRange('from', new Date(today.getFullYear() - 5, today.getMonth(), today.getDay(), 1, 1, 1, 1), false, false, false, true)

    this.searchForm.get('signatureOfCase').valueChanges.subscribe({
      next: res => {
        this.formOptionShow = res?.length > 0 ? true : false;
      }
    })


  }

  ngOnDestroy(): void {

    if (this.serachSubscription) {
      this.serachSubscription.unsubscribe();
    }

  }



  resetForm() {
    this.searchForm.get('entityName').setValue('')
    this.searchForm.get('krs').setValue('')
    this.searchForm.get('nip').setValue('')
    this.searchForm.get('textInPosition').setValue('')
    this.searchForm.get('textInBody').setValue('')
    this.searchForm.get('signatureOfCase').setValue('')
    this.searchForm.get('signatureKRS').setValue('')
    this.searchForm.get('court').setValue('')
    var today = new Date();

    this.setDateRange('to', today, false, false, false, true)
    this.setDateRange('from', new Date(today.getFullYear() - 5, today.getMonth(), today.getDay(), 1, 1, 1, 1), false, false, false, true)
  }

  selectTodayControl() {

    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    this.todayDateControl = { year: year, month: month, day: day };

  }


  selectChange() {

    let select = this.searchForm.get('signatureType').value;

    if (select == "B") {
      this.hidesignatureKRS = true;
      this.searchForm.get('signatureOfCase').setValue('');
    } else {
      this.hidesignatureKRS = false;
      this.searchForm.get('signatureKRS').setValue('');
    }
  }

  formatDatePicker(date: null) {

    // null is case for today
    let d = date ? new Date(date) : new Date();

    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();

    return { year: year, month: month, day: day };

  }

  setDateRange(formControl, date, getFive = true, setToday = false, setMin = false, init = false) {

    if (init) {

      if (formControl == "from") {

        this.startDateFrom = null;
        let formDateFrom;
        this.startDateFrom = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
        formDateFrom = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        this.searchForm.get('from').setValue(formDateFrom)




      }

      if (formControl == "to") {
        this.startDateTo = null;

        let formDateTo;


        // format for datapicker
        this.startDateTo = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
        this.minFrom = this.startDateFrom;

        // format for api
        formDateTo = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

        this.searchForm.get('to').setValue(formDateTo);
      }

    } else {

      let minDate = new Date('2001-01-01');
      let maxDate = new Date();
      
      let dateFrom = new Date(this.searchForm.get('from').value);
      let dateTo = new Date(this.searchForm.get('to').value);

      if (dateFrom > maxDate) {
        dateFrom = maxDate;

      }
      if (dateTo > maxDate) {

        dateTo = maxDate;

      }
      if (dateFrom < minDate) {

        dateFrom = minDate;

      }
      if (dateTo < minDate) {

        dateTo = minDate;

      }
      if (formControl == "from" && dateFrom > dateTo) {

        dateTo = new Date(this.searchForm.get('from').value);
      }

      if (formControl == "to" && dateFrom > dateTo) {

        dateFrom = new Date(this.searchForm.get('to').value);
      }

      this.startDateFrom = null;
      this.startDateTo = null;

      let formDateFrom;
      let formDateTo;

      // format for datapicker
      this.startDateFrom = { year: dateFrom.getFullYear(), month: dateFrom.getMonth() + 1, day: dateFrom.getDate() };
      this.startDateTo = { year: dateTo.getFullYear(), month: dateTo.getMonth() + 1, day: dateTo.getDate() };
      this.minFrom = this.startDateFrom;

      // format for api
      formDateFrom = `${dateFrom.getFullYear()}-${dateFrom.getMonth() + 1}-${dateFrom.getDate()}`;
      formDateTo = `${dateTo.getFullYear()}-${dateTo.getMonth() + 1}-${dateTo.getDate()}`;

      this.searchForm.get('from').setValue(formDateFrom)
      this.searchForm.get('to').setValue(formDateTo);
    }

  }

  createDatesObj(date) {

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let monthDD = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);

    let day = date.getDate();
    let dayDD = (date.getDate() < 10 ? '0' : '') + date.getDate();

    return {
      dateApi: { year: year, month: monthDD, day: dayDD },
      datePicker: { year: year, month: month, day: day }
    }

  }

  searchFormInit() {
    return this.formBuilder.group({
      entityName: [''],
      krs: [''],
      nip: ['', [CheckNipValidator.checkNip]],
      textInPosition: ['', [Validators.maxLength(1000)]],
      textInBody: ['', [Validators.maxLength(1000)]],
      signatureType: [this.baseList[0].val],
      signatureOfCase: [''],
      signatureKRS: [''],
      court: [''],
      from: [''],
      to: [''],
      page: [1],
    })
  }


  nipValidation() {

    this.searchForm.get('nip').valueChanges.subscribe({
      next: value => {
        let val = value == undefined ? '' : value.toString()
        let input = this.searchForm.get('nip');

        if (val.length > 0 && !input.errors?.checkNip) {
          this.blockSearchButton = true;
        } else {
          this.blockSearchButton = false;
          if (this.instanceOfPopover) {
            this.instanceOfPopover.close();
            this.instanceOfPopover = '';
          }

        }
      }
    })

  }

  showNipWarning(popover) {

    let input = this.searchForm.get('nip');
    let val = input.value?.toString()

    val = val == undefined ? '' : val

    if (val.length > 0 && !input.errors?.checkNip) {
      this.instanceOfPopover = popover;
      popover.open();
      this.blockSearchButton = true;
    } else {
      this.blockSearchButton = false;
      this.instanceOfPopover = '';
    }

  }

  fillZeros() {

    let krsForm = this.searchForm.get('krs')
    let valueWithZeros;
    let zeros;

    if (krsForm.value.length > 0 && krsForm.value.length < 10) {

      let howManyLoops = 10 - krsForm.value.length;

      for (let i = 0; i < howManyLoops; i++) {
        zeros = zeros ? zeros : '';
        zeros = `${zeros}0`
      }

      valueWithZeros = `${zeros}${krsForm.value}`

      krsForm.setValue(valueWithZeros)

    }

  }

  dateSelected(formControl, value) {
    let dateFrom = new Date(value);

    this.searchForm.get(formControl).setValue(dateFrom)

    this.setDateRange(formControl, dateFrom)


  }


  compareByID(itemOne, itemTwo) {
    return itemOne && itemTwo && itemOne.ID == itemTwo.ID;
  }

}
