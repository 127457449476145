<header>
    <div class="px-3 py-2 bg-white">
      <div class="fluid-container">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start" style="justify-content:start !important;">
          <a aria-label="przejdź do głównego widoku" alt="logo Ministerstwa Sprawiedliwości"  class="gov-pl" [routerLink]="['']"></a>
          <span class="portal-name">
            Monitor Sądowy i Gospodarczy
          </span>
          <div class="accessibility">
            <ul>
              <li>
                <a aria-label="Wersja o podwyższonym kontraście" alt="Zmień kontrast strony" (keyup.enter)="contrast()" tabindex="0" (click)="contrast()">
                  <i class="bi" [ngClass]="{'bi-eye': contrastStatus,'bi-eye-fill': !contrastStatus}" style="font-size: 2rem;"></i>
                </a>
              </li>
              <li style="font-size:2rem">
                <a 
                aria-label="Zmiana wielkości czcionki na największa" 
                alt="Czcionka o największej wielkości" 
                tabindex="0" 
                (click)="changefontSize('bigest')" 
                (keyup.enter)="changefontSize('bigest')">A</a>
              </li>
              <li style="font-size:1.6rem">
                <a 
                aria-label="Zmiana wielkości czcionki na średnią" 
                alt="Czcionka o średniej wielkości" 
                tabindex="0" 
                (click)="changefontSize('bigger')" 
                (keyup.enter)="changefontSize('bigger')">A</a>
              </li>
              <li style="font-size:1.2rem">
                <a tabindex="0"
                aria-label="Zmień wielkości czcionki na normalną" 
                alt="Czcionka o normalnej wielkości"  
                (click)="changefontSize('normal')" 
                (keyup.enter)="changefontSize('normal')">A</a>
              </li>
            </ul>

          </div>
        </div>
        
      </div>
    </div>
    <ng-content select=".subTitle"></ng-content>

  </header>