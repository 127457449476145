
import { StateService } from './state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { List } from './../models/list.model';
import { MonitorSearch } from './../models/search.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';
import { catchError, map, tap } from 'rxjs/operators';

//export const API_URL = 'https://localhost:5001/api';//'http://192.168.30.57:777/api'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "text/plain"
    }),
    withCredentials: true
  };


  httpOptionsUrl = {
    headers: new HttpHeaders({
      "Content-Type": "text/plain",
      "Accept": "text/plain"
    }),
    withCredentials: true
  };

  httpFileUpload = {
    headers: new HttpHeaders({
      "Accept": "application/json"
    }),
    withCredentials: true
  };

  httpFileDownload = {
    headers: new HttpHeaders({
      "Accept": "blob",
      "Access-Control-Expose-Headers": "*",
      "Content-Type": "application/ms-excel",
    }),
    responseType: 'blob' as 'json',
    withCredentials: true,
  };

  httpFileDownload2 = {
    headers: new HttpHeaders({
      'Accept': 'application/pdf',
      'Access-Control-Expose-Headers': 'Content-Disposition',
      "Content-Type": "application/octet-stream",
    }),
    observe: 'response' as 'body',
    responseType: 'blob' as 'pdf',
    withCredentials: true,
  };

  httpFileDownload3 = {
    headers: new HttpHeaders({
      "Accept": "blob",
      'Access-Control-Expose-Headers': '*',
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    observe: 'response' as 'body',
    responseType: 'blob' as 'json',
    withCredentials: true,
  };

  apiURL;

  constructor(@Inject(DOCUMENT) private document: any,
    private http: HttpClient, private state: StateService) {


    this.state.url$.subscribe({
      next: url => {

        this.apiURL = url;
      }
    })

    

  }

  getApiUrl() {

    let url = `${document.location.origin}/`;//document.location.href;

    this.http.get(
      `${url}home/getapiurl`,
      { responseType: 'text' }
    ).subscribe({
      next: res => {
        this.apiURL = res;
        this.state.shareUrl(this.apiURL)
      },
      error: err => {
        console.log(err)
      }
    })

  }
 
  makeUrlForGetMethod(data) {
    
    let urlForGetMethod;
    let i = 0;
    for (let key in data) {

      if (data.hasOwnProperty(key)) {
        i++;

        urlForGetMethod = `${i === 1 ? '?' : ''}${urlForGetMethod ? urlForGetMethod : ''}${i === 1 ? '' : '&'}${key}=${data[key]}`
        let value = data[key];

      }
    }

    return urlForGetMethod;
    
  }

  searchMonitor(data: MonitorSearch) {
    
    let values = this.makeUrlForGetMethod(data)
    return this.http.get<List>(`${this.apiURL}/Monitor/Search${values}`, this.httpOptions)
  }

  searchMonitorCount(data: MonitorSearch) {
   
    let values = this.makeUrlForGetMethod(data)
    return this.http.get(`${this.apiURL}/Monitor/SearchCount${values}`, this.httpOptions)
  }

  getMonitorDetails(id) {
    return this.http.get(`${this.apiURL}/Monitor/Detalis?Id=${id}`, this.httpOptions)
  }

  getFileMonitor(id): Observable<any> {
    return this.http.get(`${this.apiURL}/Monitor/Download?id=${id}`, this.httpOptions)

  }

  getMonitorByYear(year) {
    return this.http.get(`${this.apiURL}/Monitor/GetFiles?year=${year}`, this.httpOptions);

  }

}
