import { Component,
         OnInit,
         Input,
         Output,
         EventEmitter,
         ElementRef,
         Renderer2,
         ViewChild,
         Injectable,
         AfterViewInit} from '@angular/core';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})

@Injectable({
  providedIn: 'root',
})
export class AlertComponent implements OnInit, AfterViewInit {

  @Input() alertConf: any;
  @Output() closeAlertTime;
  @Output() closedAlert: EventEmitter<boolean> = new EventEmitter<boolean>();
  duration = 5000;

  @ViewChild('alert', { static: false }) alert: ElementRef;

  alertDiv;

  constructor(private el: ElementRef,
              private renderer: Renderer2) { }

  ngOnInit() {

    if (!Array.isArray(this.alertConf))
      this.alertConf = [this.alertConf];
  }

  ngAfterViewInit() {
    this.alertDiv = this.alert?.nativeElement;

    this.alertConf.forEach(item => {

      if (item.alwaysVisible)
        return;

      setTimeout(() => {
        this.closeAlert();
      }, this.duration); 
    });
  }


  closeAlert(index = null, e = null) {

    if(e) {
      let alertEl = e.target.parentElement.parentElement.parentElement.parentElement
    
      this.renderer.removeClass(alertEl, 'fadeIn');
      this.renderer.addClass(alertEl, 'fadeOut');

      setTimeout(() => {
        this.alertConf.splice(index, 1)
        if (this.alertConf.length == 0)
          this.closedAlert.emit(true);
      }, 400);
    }
    else {
      this.renderer.addClass(this.alertDiv, 'animated');
      this.renderer.addClass(this.alertDiv, 'fadeOut');

      setTimeout(() => {
        this.closedAlert.emit(true);
      }, 300);

    }
  }
}
