import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {


  data$ = new BehaviorSubject(null);
  searchRequest$ = new BehaviorSubject(null);
  alertBody$ = new Subject();
  url$ = new BehaviorSubject(null);
  accessibility$ = new BehaviorSubject(null);

  shareData( data = null ) {
    this.data$.next(data);
  }

  shareSearchRequest( data = null ) {
    this.searchRequest$.next(data);
  }

  shareAlertBody( body ) {
    this.alertBody$.next(body);
  }

  shareUrl( url ) {

    this.url$.next(url);
  }

  shareAccessibility( data ) {
    this.accessibility$.next(data);
  }
}

