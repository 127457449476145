import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {NgbDatepickerConfig, NgbCalendar, NgbDateStruct, NgbInputDatepickerConfig, NgbDate, NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { config } from 'rxjs';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [NgbInputDatepickerConfig] 
})
export class DatePickerComponent implements OnInit, OnChanges {

  @Input('popover') popoverText;
  @Input('startValue') startValue;
  @Input('minDate') minDateRange;
  @Input('maxDate') maxDateRange;
  @Input('rangeTo')
  @Output('valueSelected') valueSelected: EventEmitter<any> = new EventEmitter();


  public modelDate: NgbDateStruct;


  setStartDate;
  dateValue;

  constructor(config: NgbInputDatepickerConfig, private calendar: NgbCalendar) { 

    config.outsideDays = 'hidden';

  
  }

  ngOnInit(): void {
  


  }

  selectToday() {
    this.modelDate = this.calendar.getToday();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes.startValue) {

      this.setStartDate = null;
      this.setStartDate = changes.startValue.currentValue;
      
 
      if(this.setStartDate) {

        this.dateValue = null;

        setTimeout(() => {
          //this.dateValue = `${this.setStartDate.year}-${this.setStartDate.month}-${this.setStartDate.day}`;
          this.dateValue = this.formatForApi(`${this.setStartDate.year}-${this.setStartDate.month}-${this.setStartDate.day}`);
        }, 0);
        
      
      }
      
    }

    if(changes.minDate) {

      this.minDateRange = null;
      this.minDateRange = changes.minDate.currentValue;
      
      
    }

    if(changes.maxDate) {

      this.minDateRange = null;
      this.minDateRange = changes.maxDate.currentValue;
      

    }

  }

  formatForApi(date) {

    let d = new Date(date);

    let year = d.getFullYear();
    let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    let day = (d.getDate() < 10 ? '0' : '') + d.getDate();

    return `${year}-${month}-${day}`;

  }

  changePropertyValue() {

    let dateToChange = `${this.modelDate.year}-${this.modelDate.month}-${this.modelDate.day}`;
    let d = new Date( `${this.modelDate.year}-${this.modelDate.month}-${this.modelDate.day}`)
    this.valueSelected.emit(this.formatForApi(dateToChange));
  }

}
