import { AlertComponent } from './shared/alert/alert.component';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableListComponent } from './table-list/table-list.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import { CommonModule, registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { FullsizeLoaderComponent } from './shared/fullsize-loader/fullsize-loader.component';
import { ListByYearComponent } from './list-by-year/list-by-year.component';
import { MyInterceptor } from './services/Interceptor';
import { FormatBytesPipe } from './services/format-bytes.pipe';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { DeclarationComponent } from './declaration/declaration.component';

registerLocaleData(localePl, 'pl');

@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    DatePickerComponent,
    TableListComponent,
    SearchFormComponent,
    MainHeaderComponent,
    FullsizeLoaderComponent,
    ListByYearComponent,
    FormatBytesPipe,
    AppFooterComponent,
    DeclarationComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
  ],
  entryComponents: [AlertComponent],
  exports: [
    ReactiveFormsModule,
    FormsModule],
  providers: [
  { 
    provide: LOCALE_ID, 
    useValue: "pl-PL" }, 
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MyInterceptor,
    multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
