<footer>
    <div>
        &#169; 2022 Wszystkie prawa zastrzeżone
    </div>
    <div class="version">
        Wersja: 1.0.1
    </div>
    <div class="instructions">
        <a aria-label="Instrukcja użytkowania" alt="Instrukcja użytkowania" href (keyup.enter)="openInstructions()" (click)="openInstructions();">Instrukcja użytkowania</a>
    </div>
    <div class="declaration">
        <a aria-label="przejdź deklaracji dostępności" alt="Deklaracja dostępności" [routerLink]="['/deklaracja-dostepnosci']"
        role="link">Deklaracja dostępności</a>
    </div>
    <div class="logo-footer">
        <a aria-label="przejdź do głównego widoku" alt="logo Ministerstwa Sprawiedliwości"  class="gov-pl" [routerLink]="['']" ></a>
    </div>
</footer>
