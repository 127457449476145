import { StateService } from './services/state.service';
import { AlertComponent } from './shared/alert/alert.component';
import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef, ViewEncapsulation, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'msig';
  alertBody;
  alertInstance;
  public static apiURL: string;


  @ViewChild('alertContainer', {read: ViewContainerRef, static: true}) alertrContainer: ViewContainerRef;

  constructor( 
    private componentFactoryResolver: ComponentFactoryResolver,
    private state: StateService,
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient){}



  ngOnInit(): void {

    this.getApiUrl()

    this.state.alertBody$.subscribe((body) => {
      this.alertBody = body;
      this.alertMessage();
    });
    
  }    


  getApiUrl() {

    let url = `${document.location.origin}/`;
   
    this.http.get(
      `${url}home/getapiurl`,
      { responseType: 'text' }
    ).subscribe({
      next: res => {
       
        this.state.shareUrl(res)
        this.setUrlToStorage(res)

      },
      error: err => {
        console.log(err)
      }
    })

  }


  setUrlToStorage(url) {
    sessionStorage.setItem('API_URL', url);
  }

  alertMessage() {

    if (this.alertrContainer.get(0) !== null) {
      return;
    }

    const alerComponent = this.componentFactoryResolver.
    resolveComponentFactory(AlertComponent);

    const alertInfoRef = this.alertrContainer.createComponent(alerComponent);
    alertInfoRef.instance.alertConf = this.alertBody;

    alertInfoRef.instance.closedAlert.subscribe(close => {
      this.alertrContainer.clear();
    });

    this.alertInstance = alertInfoRef;
  }

}
