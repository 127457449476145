<fullsize-loader *ngIf="fullsizeLoader"></fullsize-loader>

<main-header>
  <div class="subTitle px-3">
    <div class="fluid-container d-flex flex-wrap justify-content-center">
      <div aria-label="Wyszukiwanie ogłoszeń" class="title">Wyszukiwanie ogłoszeń</div>
      
    </div>
  </div>
</main-header>

<div class="container" style="margin-top:25px" [formGroup]="searchForm">
  <div class="row">
     <!-- <pre>{{ searchForm.value | json }}</pre>  -->
    <!-- <pre>{{ searchForm.valid | json }}</pre>
    <pre>NIp: {{ this.searchForm.get('nip').errors?.checkNip | json }}</pre> -->
  </div>
  <div class="mb-5"> 
    <div class="row" style="text-align: center; display: block;">
      <a class="btn btn-link table-action_link mt-2" style="font-size:1.1rem;font-weight: 500;" [routerLink]="['/pobieranie-monitora', year]"
      role="link" aria-label="Przejdź do pobierz monitor" alt="Pobierz monitor">Pobierz monitor</a>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="mb-2">
        <label for="formControlEntityName" class="form-label"
          >Nazwa podmiotu</label
        >
        <input
          aria-label="Wpisz nazwę podmiotu"
          formControlName="entityName"
          autocomplete="off"
          type="text"
          class="form-control"
          id="formControlEntityName"
          placeholder="Wpisz nazwę podmiotu"
          (keyup.enter)="searchList()"
        />
      </div>
      <div class="mb-2">
        <label for="formControlKrs" class="form-label"
          >Numer KRS</label
        >
        <input
          formControlName="krs"
          autocomplete="off"
          mask="0000000000"
          aria-label="Wpisz KRS"
          type="text"
          class="form-control"
          id="formControlKrs"
          placeholder="Podaj nr KRS"
          (keyup.enter)="searchList()"
          (blur)="fillZeros()"
        />
      </div>
      <div class="mb-2">

        <ng-template #popoverNip let-greeting="greeting"><span class="error">Nieprawidłowy numer NIP</span></ng-template>

        <label for="formControlNip" class="form-label">NIP</label>
        <input 
          onkeydown="return event.keyCode !== 69"
          formControlName="nip"
          autocomplete="off"
          type="text"
          aria-label="Wpisz NIP"
          maxlength="13"
          class="form-control"
          id="formControlNip"
          placeholder="Podaj NIP"
          triggers="manual"
          [ngbPopover]="popoverNip" 
          #p2="ngbPopover"
          [autoClose]="false"
          (blur)="showNipWarning(p2)"
          (keyup.enter)="searchList(p2)"
        />
      </div>
      <div class="mb-2">
        <label for="textInPosition" class="form-label"
          >Tekst w pozycji</label
        >
        <textarea
          formControlName="textInPosition"
          autocomplete="off"
          maxlength="1000"
          class="form-control"
          aria-label="Wpisz lub wklej tekst w treści"
          id="textInPosition"
          rows="4"
          placeholder="Wpisz szukany tekst"
          (keyup.enter)="searchList()"
      ></textarea>
      </div>
      <div class="mb-2">
        <label for="textInBody" class="form-label"
          >Tekst w treści</label
        >
        <textarea
          formControlName="textInBody"
          maxlength="1000"
          autocomplete="off"
          aria-label="Wpisz lub wklej tekst w treści"
          class="form-control"
          id="textInBody"
          rows="4"
          placeholder="Wpisz szukany tekst"
          (keyup.enter)="searchList()"
        ></textarea>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">

      <div class="mb-2">
        <label for="signatureType" class="form-label"
          >Typ ogł./sprawy</label
        >
        <select
          formControlName="signatureType"
          aria-label="wybierz Typ ogłoszenia lub sprawy"
          class="form-control"
          aria-label="Default select example"
          [compareWith]="compareByID"
          (change)="selectChange()"
        >
        <option *ngFor="let base of baseList" aria-label="{{base.name}}"
           [ngValue]="base.val">
            {{base.name}}
          </option>
        </select>
      </div>
      <div *ngIf="!hidesignatureKRS" class="mb-2">
        <label for="signatureOfCase" class="form-label"
          >Sygnatura sprawy

          <span tabindex="0" #p1="ngbPopover" (keyup.enter)="p1.open()" class="bi bi-info-circle information-title"
          [autoClose]="true"  
          ngbPopover="Prosimy wpisać numer sprawy nadanej przez sąd">
          </span>
        </label>
        <input
          formControlName="signatureOfCase"
          aria-label="Wpisz sygnaturę sprawy"
          autocomplete="off"
          type="text"
          class="form-control"
          id="signatureOfCase"
          placeholder="Sygnatura sprawy"
        />
      </div>
      <div *ngIf="formOptionShow" class="mb-2">
        <label for="court" class="form-label"
          >Sąd
          <span tabindex="0" #p2="ngbPopover" (keyup.enter)="p2.open()"
          [autoClose]="true" class="bi bi-info-circle information-title"
            ngbPopover="Proszę wskazać sąd, w którym toczy się postępowanie"
          >
          </span>
        </label>
        <input
          formControlName="court"
          autocomplete="off"
          aria-label="Proszę wskazać sąd, w którym toczy się postępowanie"
          type="text"
          class="form-control"
          id="court"
          placeholder="Miasto"
        />
      </div>
      <div *ngIf="hidesignatureKRS" class="mb-2">
        <label for="signatureKRS" class="form-label"
          >Sygnatura KRS</label
        >
        <input
          formControlName="signatureKRS"
          autocomplete="off"
          type="text"
          aria-label="Wpisz Sygnaturę KRS"
          class="form-control"
          id="signatureKRS"
          placeholder="Wpisz sygnaturę KRS"
        />
      </div>
      <div class="mb-2">
        <label for="formControlDateFrom" class="form-label"
          >Data publikacji od
          <ng-template #popContent>
            {{ popoverTexts.dateFrom.text }}
            <br /><br />
            <i>Przykład 1:</i><br />
            <i>Data publikacji od: 2015-01-01</i><br />
            <i>Data publikacji do: 2020-01-01</i>
            <br /><br />
            <i>Przykład 2:</i><br />
            <i>Data publikacji od: 2012-01-01</i><br />
            <i>Data publikacji do: 2017-01-01</i>
          </ng-template>
          <span 
            [ngbPopover]="popContent" 
            tabindex="0" 
            #p3="ngbPopover"
            [autoClose]="true"
            (keyup.enter)="p3.open()" 
            class="bi bi-info-circle information-title">
          </span>
        </label>


        <app-date-picker
          aria-label="Wybierz datę publikacji do. Przełączaj się strzałkami i klawiszem TAB, akceptacja nacisnij Enter, cofnięcie się z danej sekcji daty to ESC"
          [minDate]="mindateControl"
          [maxDate]="todayDateControl" 
          [startValue]="startDateFrom"
          (valueSelected)="dateSelected('from', $event)"
          [popover]="popoverTexts.dateFrom"
        ></app-date-picker>
      </div>
      <div class="mb-5">
        <label for="formControlDateTo" class="form-label"
          >Data publikacji do

          <ng-template #popContent>
            {{ popoverTexts.dateTo.text }}
            <br /><br />
            <i>Przykład 1:</i><br />
            <i>Data publikacji od: 2016-01-01</i><br />
            <i>Data publikacji do: 2020-12-31</i>
            <br /><br />
            <i>Przykład 2:</i><br />
            <i>Data publikacji od: 2011-01-01</i><br />
            <i>Data publikacji do: 2015-12-31</i>
          </ng-template>
          <span class="bi bi-info-circle information-title"
          [ngbPopover]="popContent" 
          tabindex="0" 
          [autoClose]="true"
          #p4="ngbPopover"
          (keyup.enter)="p4.open()">
          </span>
        </label>
         
        <app-date-picker
          aria-label="Wybierz datę publikacji do. Przełączaj się strzałkami i klawiszem TAB, akceptacja nacisnij Enter, cofnięcie się z danej sekcji daty to ESC"
          [minDate]="mindateControl"
          [maxDate]="todayDateControl" 
          [startValue]="startDateTo"
          (valueSelected)="dateSelected('to', $event)"
          [popover]="popoverTexts.dateTo"
        ></app-date-picker>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mb-2 d-flex flex-wrap justify-content-end">
        <button
          type="button"
          class="btn btn-primary mr-3"
          (click)="searchList()"
          [disabled]="blockSearchButton"
          aria-label="Przycisk formularza szukaj"
          alt="Szukaj"
        >
          Szukaj
        </button>
        <button type="button" (click)="resetForm()" role="button" class="btn btn-secondary" alt="wyczyść formularz" aria-label="Wyczyść formularz" >Wyczyść</button>
      </div>
    </div>
  </div>
</div>


