<fullsize-loader *ngIf="fullsizeLoader"></fullsize-loader>

<main-header>
  <div class="subTitle px-3 mb-3">
    <div class="fluid-container d-flex flex-wrap justify-content-start">
      <a class="arrow-back" role="link" aria-label="Powrót do ekranu głównego" [routerLink]="['']">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16" >
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
        Wstecz
      </a>
      <div class="title">
        Wynik wyszukiwania
      </div>
    </div>
  </div>

</main-header>

<div class="container table-container" style="margin-top:75px" *ngIf="msigList">
  <!-- <h5 class="mb-4">Wynik wyszukiwania</h5> -->

  <table class="table">
    <thead class="thead-normal">
      <tr>
        <th scope="col">Numer monitora</th>
        <th scope="col">Data</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let msigRow of msigList?.list; index as i">
        <td class="center-content" style="vertical-align: center;">
          {{ msigRow?.monitorNumber }}
        </td>
        <td class="center-content" style="vertical-align: center;">{{ msigRow.dateOfPublication | date:'yyyy-LL-dd' }}</td>
        <td class="center-content">
          <div class="d-flex flex-wrap justify-content-end">
            <button
              type="button"
              class="btn btn-link mr-3 table-action_link"
              (click)="showModalDetails(content, msigRow.id)"
            >
              Szczegóły
            </button>
            <a type="button" class="btn btn-link table-action_link" download href="{{apiURL}}/Monitor/Download?id={{msigRow.id}}&fileId=false" >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
              </svg>
              Pobierz</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">

    <div *ngIf="countPages" class="pages">strona <span>{{ msigList.page }}</span> z <span>{{ countPages }}</span></div>
    <div class="d-flex justify-content-between">
      <div *ngIf="hidePrev" tabindex="0" class="btn mr-3 prev p-2"  (keyup.enter)="changePage('prev')" (click)="changePage('prev')">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
        Poprzednia</div>
        <div *ngIf="!hidePrev" style="color: #888;" class="btn mr-3 next p-2">Poprzednia</div>

      <div *ngIf="hideNext" tabindex="0" class="btn mr-3 next p-2" (keyup.enter)="changePage('next')" (click)="changePage('next')">Następna
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </div>
      <div *ngIf="!hideNext" style="color: #888;" class="btn mr-3 next p-2">Następna</div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header" *ngIf="monitorDetails">
    <h4 class="modal-title">{{ monitorDetails?.entityName }}
        <div class="modal-title_sub">Numer ogłoszenia: <span>{{ monitorDetails?.numberOfNotice }}</span></div>
        <div class="modal-title_sub">Sygnatura sprawy: <span>{{ monitorDetails?.signatureOfCase }}</span></div>
    </h4>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body">
    <div>
        <div class="modal-title_sub">KRS: <span>{{ monitorDetails?.krs }}</span></div>
        <div class="modal-title_sub">NIP: <span>{{ monitorDetails?.nip }}</span></div>
    <br/>
        <div class="modal-title_sub">Numer strony: <span>{{ monitorDetails?.page }}</span></div>
        <div class="modal-title_sub">Rozdział/nazwa rozdziału: <span>{{ monitorDetails?.chapterName }}</span></div>
        <div class="modal-title_sub">Nr monitora: <span>{{ monitorDetails?.monitorNumber }}</span></div>
        <div class="modal-title_sub">Data publikacji: <span>{{ monitorDetails?.dateOfPublication | date:'yyyy-LL-dd' }}</span></div>

        <div class="modal-title_sub">Treść nagłówka: <span>{{ monitorDetails?.textInPosition }}</span></div>
        <div class="mt-4 mb-2">
            <label for="exampleFormControlInput1" class="form-label">Treść ogłoszenia</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="8" readonly placeholder="Treść">
              {{ monitorDetails?.textInBody }}</textarea>
          </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
    type="button" class="btn btn-secondary"
      (click)="modal.close('Close click')"
    >
      Zamknij
    </button>
    <a type="button" class="btn btn-primary" download href="{{apiURL}}/Monitor/Download?id={{monitorDetails.id}}&fileId=false">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
      </svg>
      Pobierz</a>
    <button type="button" [disabled]="!nextPrevIdsObj.prev" class="btn btn-secondary" (click)="modal.close('Close click')" (click)="showModalDetails(content, nextPrevIdsObj.prev)">Poprzedni</button>
    <button type="button" [disabled]="!nextPrevIdsObj.next" class="btn btn-secondary" (click)="modal.close('Close click')" (click)="showModalDetails(content, nextPrevIdsObj.next)">Następny</button>
  </div>
</ng-template>
