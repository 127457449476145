
<main-header>
  <div class="subTitle px-3 mb-3">
    <div class="fluid-container d-flex flex-wrap justify-content-start">
      <a class="arrow-back" role="link" aria-label="Powrót do ekranu głównego" [routerLink]="['']">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16" >
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
        Wstecz
      </a>
      <div class="title">
        Deklaracja dostępności
      </div>
    </div>
  </div>

</main-header>

<div class="container table-container" style="margin-top: 2rem;" >
    <article style="padding:1rem 0">
        <h2>Deklaracja dostępności Ministerstwa Sprawiedliwości</h2>
    </article>
    <section>
        <div id="dec">
            <p id="a11y-wstep">
            <span id="a11y-podmiot">Ministerstwo Sprawiedliwości</span>
            zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych
            i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do strony internetowej
            <a id="a11y-url" href="https://www.gov.pl/web/sprawiedliwosc">Ministerstwa Sprawiedliwości.</a>
            </p>
            <br>
            <p>Data publikacji strony internetowej: <span id="a11y-data-publikacji">2019.08.05</span></p>
            <p>Data ostatniej dużej aktualizacji: <span id="a11y-data-aktualizacja">2019.08.05</span></p>
            <br>
            <p id="a11y-status">
            Strona internetowa jest częściowo zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych
            podmiotów publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej:</p>
            <ol id="list">
            <li>Mogą zdarzyć się sytuacje, że pomimo starań redaktorów serwisu, niektóre dokumenty opublikowane na stronie są niedostępne z uwagi na fakt, że:
            <ul id="list">
            <li>pochodzą z różnych źródeł;</li>
            <li>zostały opublikowane w oparciu o zasady przyjęte w innej instytucji;</li>
            <li>są bardzo obszerne, a ich wytworzenie odbywało się w kilku komórkach organizacyjnych, co uniemożliwiało wpływ na ich docelową treść oraz kształt;</li>
            <li>zostały opublikowane przed wejściem w życie ustawy o dostępności cyfrowej.</li>
            </ul>
            </li>
            <li>Możliwe jest, że niektóre filmy i grafiki zamieszczone w serwisie – opublikowane przed wejściem w życie ustawy o dostępności cyfrowej –
            mogą nie posiadać napisów lub innych wymaganych elementów zapewniających dostępność.</li>
            <li>Niektóre zdjęcia mogą nie zawierać poprawnych opisów alternatywnych.</li>
            </ol>
            <br>
            <p>Oświadczenie sporządzono dnia: <span id="a11y-data-soprzadzenie">2021-05-17.</span></p>
            <br>
            <p>Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny.</p>
            <br>
            <p>Na stronie internetowej można korzystać ze standardowych skrótów klawiaturowych.</p>
            <br>
            <h3 id="a11y-kontakt">Informacje zwrotne i dane kontaktowe</h3>
            <br>
            <p>W przypadku problemów z dostępnością strony internetowej prosimy o kontakt pod adresem mailowym <span id="a11y-email"><a href="mailto:dostepnosc_cyfrowa@ms.gov.pl">dostepnosc_cyfrowa@ms.gov.pl</a></span> Kontaktować można się także dzwoniąc na numer telefonu <span id="a11y-telefon">+48 22 23 90 243.</span></p>
            <br>
            <p>Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać skargi na brak zapewnienia dostępności.</p>
            <br>
            <p id="a11t-procedura">Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu.
            Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, na przykład przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości
            filmu bez audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz
            sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej sposób
            przedstawienia tej informacji. Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z żądaniem.
            Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym wnoszącego żądanie, kiedy realizacja żądania będzie możliwa, przy czym
            termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem. Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, podmiot publiczny może
            zaproponować alternatywny sposób dostępu do informacji. W przypadku, gdy podmiot publiczny odmówi realizacji żądania zapewnienia dostępności lub alternatywnego
            sposobu dostępu do informacji, wnoszący żądanie możne złożyć skargę w sprawie zapewniana dostępności cyfrowej strony internetowej, aplikacji mobilnej lub elementu
            strony internetowej, lub aplikacji mobilnej.
            <br>
            Po wyczerpaniu wszystkich możliwości skargę można przesłać także do <a href="https://www.rpo.gov.pl/content/jak-zglosic-sie-do-rzecznika-praw-obywatelskich">Rzecznika Praw Obywatelskich.</a>
            </p>
            <br>
            <h3 id="a11y-architektura">Dostępność architektoniczna</h3>
            <br>
            Do wszystkich obiektów umożliwiony jest wjazd osobom na wózkach inwalidzkich przy pomocy wind zewnętrznych (Al. Ujazdowskie 11, ul. Czerniakowska 100)
            lub podjazdu (ul. Chopina 1, ul. Zwycięzców 34). Wszystkie obiekty posiadają windy osobowe, umożliwiające poruszanie się i dostęp na każde piętro budynków, gdyż windy
            te zostały wyposażone w przyciski sterujące, umożliwiające odczyt osobom niewidomym i niedowidzącym, jak również sygnalizację dźwiękową informującą o pozycji windy.
            Zlikwidowane zostały bariery w postaci progów i różnic w poziomie podłóg pokoi i korytarzy.
            Ponadto wszystkie budynki Ministerstwa, które nie są zabytkami, posiadają toalety dostosowane dla osób niepełnosprawnych.
            W budynkach, które są pod opieką konserwatora zabytków, tj.:
            <ul id="list2">
            <li>w budynku przy Al. Róż 2 (wpisanym do rejestru zabytków pod nr 595 – decyzja z dn. 01.07.1965r.), w 2016r zakończono remont kapitalny, w ramach którego uwzględniono wymagania osób niepełnosprawnych,</li>
            <li>w budynku przy Al. Ujazdowskich 19 (wpisanym do rejestru zabytków pod nr 749 – decyzja z dn. 01.07.1965r.) zaplanowano na kolejne lata remont generalny (jednakże z uwagi na wniesione do przedmiotowego obiektu roszczenia własnościowe, termin rozpoczęcia prac jest nieznany), gdzie uwzględniono przepisy w zakresie dostosowania do potrzeb osób niepełnosprawnych ogólnodostępnych części budynku, wynikające z uchwały Sejmu Rzeczypospolitej Polskiej z dnia 01.08.1997r – Karta Praw Osób Niepełnosprawnych.</li>
            </ul>
            </div>
    </section>

</div>
