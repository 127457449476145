<div class="alert-wraper" #alert *ngIf="alertConf">
  <div class="container" style="padding-left: 0px !important; padding-right: 0px !important;">
  <ng-container *ngFor="let item of alertConf; let i = index">
    <ng-container *ngIf="item != null">
      <div class="alert-body animated faster fadeIn {{ item.status }}">

        <div class="message" *ngIf='item.message'>
          {{ item.message }}
        </div>

        <div class="close-alert">
          <div (click)="closeAlert(i, $event)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
              <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>


</div>

